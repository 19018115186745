import React, { useEffect, useState } from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"

import HeaderInsegne from "../components/HeaderInsegne"

import logo from "../static/logos/logo-pam-a-casa.png"

import headerBg from "../images/insegne/pam-a-casa.png"
import banner from "../images/pam-a-casa-banner.png"
import bannerMobile from "../images/pam-a-casa-banner-mobile.png"

import "../utils/page-insegne.scss"
import "../utils/general.scss"

const PamACasa = ({ pageContext }) => {
    let [bannerImg, setBannerImg] = useState(banner)

    useEffect(() => {
        setBannerImg(window.outerWidth < 720 ? bannerMobile : banner)
        window.addEventListener("resize", () => {
            setBannerImg(window.outerWidth < 720 ? bannerMobile : banner)
        })
    }, [])

    return (
        <Layout pageContext={pageContext}>
            <SEO
                title={`Pam A Casa | ${pageContext.localeResources["menu.insegne"]}`}
            >
                {typeof window !== "undefined" && (
                    <link href={window.location} rel="canonical" />
                )}
            </SEO>

            <HeaderInsegne
                title={pageContext.localeResources["insegne.pamACasa.title"]}
                text={pageContext.localeResources["insegne.pamACasa.text"]}
                logo={logo}
                link="https://www.pamacasa.it/"
                linkText={
                    pageContext.localeResources["insegne.pamACasa.linkText"]
                }
                headerImg={headerBg}
            />

            <div className="container section">
                <img
                    src={bannerImg}
                    style={{ width: "100%" }}
                    className="rounded"
                />
            </div>
        </Layout>
    )
}

export default PamACasa
